import { gql } from '@apollo/client';

export const COUNTRIES = gql`
  query Countries {
    config {
      countries {
        countryName
      }
    }
  }
`;
export const ConfigQuery = gql`
  query Config {
    config {
      tripCount
      salesCount {
        count
        date
      }
      reviews {
        count
        rating
        ratingCount(ratings: ["5", "4"])
      }
    }
  }
`;

export const WeeklySalesCount = gql`
  query WeeklySalesCount {
    config {
      weeklyTripSaleCount
    }
  }
`;

export const SalesCountQuery = gql`
  query SalesCount {
    config {
      salesCount {
        count
        date
      }
    }
  }
`;
