import { GImage } from '@/Components/g-image';
import { SalesCountQuery } from '@/Lib/graphql/queries/config.query';
import { useDevice } from '@/Lib/hooks/useDevice';
import { SalesCount } from '@/Lib/types/count-data';
import { HeaderData } from '@/Lib/types/header';
import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';

type Props = {
  headerData: HeaderData;
};

const SocialProof = ({ headerData }: Props) => {
  const { isLG } = useDevice();
  const imageNames = isLG ? [1, 2, 3, 4] : [2, 3, 4];

  const salesCountData = headerData?.config.data?.config.salesCount;

  const [
    getSalesCount,
    { data: freshSalesCount, loading: freshSalesCountLoading },
  ] = useLazyQuery<SalesCount>(SalesCountQuery);

  const startOfDay = Math.floor(new Date().setUTCHours(0, 0, 0, 0) / 1000);

  useEffect(() => {
    if (salesCountData && salesCountData.date < startOfDay) {
      getSalesCount();
    }
  }, [salesCountData?.date]);

  return (
    <div className="flex items-center gap-1 flex-1-only lg:ml-5 xl:ml-12">
      <div className="flex">
        {imageNames.map((imageName, index) => (
          <div
            key={index}
            className="rounded-full overflow-hidden relative border-2 border-light-600"
            style={{ left: index * -6 }}
          >
            <GImage
              width={isLG ? '1.5rem' : '1.25rem'}
              height={isLG ? '1.5rem' : '1.25rem'}
              crop_w={48}
              crop_h={48}
              path={`website/static/header-social-proof/${imageName}`}
              alt={`generic avatar ${imageName}`}
              styles={{
                width: isLG ? '1.5rem' : '1.25rem',
                height: isLG ? '1.5rem' : '1.25rem',
              }}
              visibleByDefault
            />
          </div>
        ))}
      </div>
      <p className="font-semibold text-2xs lg:text-sm leading-0.7rem text-dark-900 md:text-dark-700 relative -left-3 lg:-left-4 mt-px">
        {freshSalesCountLoading
          ? '... '
          : freshSalesCount?.config.salesCount.count?.toLocaleString() ??
            salesCountData?.count?.toLocaleString()}
        {!freshSalesCountLoading && '+ '}
        dreams turned into reality
      </p>
    </div>
  );
};

export { SocialProof };
